<template>
  <div class="post_page">
    <div class="post_list">
      <div v-for="(item, index) in postList" :key="item.id" :class="[item.state == 2 ? 'refuse' : '']" class="post_item">
        <div>
          <h4 @click="toDetails(item.id, item.state)" class="violet">{{ item.title }}</h4>
          <a :class="[ item.state == 0 ? 'havent' : item.state == 1 ? 'already' : 'refuse',]">
            {{ item.stateString }}
          </a>
        </div>
        <p>发布于{{ item.publishDateString }}</p>
        <p @click="toDetails(item.id)">
          {{ item.topic ? "#" + item.topic + "#" : "" }}{{ item.introduction }}
        </p>
        <div>
          <a v-for="(industryItem, industryIndex) in item.industryName" :data-id="item.industry[industryIndex]">
            {{ industryItem }}
          </a>
          <a v-for="(productItem, productIndex) in item.productName" :data-id="item.product[productIndex]">
            {{ productItem }}
          </a>
        </div>
        <div>
          <a @click="like(item.id, item.isLike, item.state, index)" :class="[ item.isLike ? 'active' : '', item.state == 1 ? 'pointer' : '',]" title="点赞">
            <svg-icon icon-class="sword"></svg-icon>
            <svg-icon icon-class="sword_a"></svg-icon>
            <span>{{ item.likeCount }}</span>
          </a>
          <a @click="collect(item.id, item.isCollect, item.state, index)" :class="[ item.isCollect ? 'active' : '', item.state == 1 ? 'pointer' : '']" title="收藏">
            <svg-icon icon-class="shield"></svg-icon>
            <svg-icon icon-class="shield_a"></svg-icon>
            <span>{{ item.collectCount }}</span>
          </a>
          <a :class="[item.isComment ? 'active' : '']" title="评论">
            <svg-icon icon-class="discuss"></svg-icon>
            <svg-icon icon-class="discuss_a"></svg-icon>
            <span>{{ item.comment }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="noData" v-if="this.postList.length == 0">
      <img src="@/assets/PC/profile/listNone.png" alt="" />
    </div>
    <div class="post_pagination" v-if="recordCount > pageSize">
      <el-pagination
        background
        layout="pager, next"
        next-text="下一页"
        :page-size="pageSize"
        :pager-count="pageCount"
        :total="recordCount"
        @current-change="pageClick"
        :current-page="listParams.page"
        @next-click="pageClick"
      >
      </el-pagination>
    </div>
    <a class="onBottomLoad_btn" @click="getList" v-if="!noMore">点击加载</a>
  </div>
</template>

<script>
import Headers from "@/components/header.vue";
import * as forum from "../../api/forum.js";
import { debounce } from "@/utils/common";
import { dataState } from "@/api/all.js";

export default {
  name: "post",
  components: {
    Headers,
  },
  props: ["searchVal", "isSearch"],
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth < 768) {
        this.isPc = false;
      } else {
        this.isPc = true;
      }
    },
    searchVal(val) {
      this.searchTitle = val;
    },
    isSearch(val) {
      if (val) {
        this.search();
      }
    },
  },
  created() {
    if (this.screenWidth < 768) {
      this.isPc = false;
    } else {
      this.isPc = true;
    }
    this.$emit("change", 4, "我的发帖");
    this.getList();
    this.isInitial = false;
  },
  mounted() {
    // 数据统计
    const data = {
      visitType: 1,
      gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
      url: window.location.href,
      pageUrl: "Post",
      relationId: "",
    };
    dataState(data).then((res) => {
      sessionStorage.setItem("gid", res.data);
    });
  },
  data() {
    return {
      searchTitle: "",
      noMore: true,
      pageSize: 5,
      pageCount: 5,
      recordCount: 0,
      isPc: true,
      isInitial: true,
      screenWidth: document.body.clientWidth,
      listParams: {
        page: 1,
        limit: 5,
        productType: 0,
        title: "",
      },
      postList: [],
    };
  },
  methods: {
    search() {
      this.isInitial = true;
      this.listParams.page = 1;
      this.listParams.title = this.searchTitle;
      this.getList();
    },
    getList() {
      if (!this.isPc && !this.isInitial) {
        this.listParams.page++;
      }
      forum.getPost(this.listParams).then((res) => {
        if (res.status == 1) {
          res.data.map((item, index) => {
            if (item.industry != "") {
              item.industry = JSON.parse(item.industry);
              item.industryName = JSON.parse(item.industryName);
            } else {
              item.industry = [];
              item.industryName = [];
            }
            if (item.product != "") {
              item.product = JSON.parse(item.product);
              item.productName = JSON.parse(item.productName);
            } else {
              item.product = [];
              item.productName = [];
            }
          });
          if (!this.isPc) {
            this.postList.push(...res.data);
            if (this.postList.length == res.recordCount) {
              this.noMore = true;
            } else {
              this.noMore = false;
            }
          } else {
            this.postList = res.data;
          }
          this.isInitial = false;
          this.recordCount = res.recordCount;
        }
      });
    },
    // !点击去往详情页
    toDetails(id, state) {
      if(state == 0 || state == 2){
        this.$router.push("/Home/forumDetails?id=" + id + '&state=1');
      }else{
        this.$router.push("/Home/forumDetails?id=" + id)
      }
    },
    pageClick(page) {
      this.listParams.page = page;
      this.getList();
    },
    like(id, isLike, state, index) {
      debounce(() => {
        if (state == 1) {
          if (!isLike) {
            forum.likeArticle({ forumManagementId: id }).then((res) => {
              if (res.status == 1) {
                if (res.message > 0) {
                  this.$toast("点赞成功！ +" + res.message + " 积分");
                } else {
                  this.$toast("点赞成功！");
                }
                this.postList[index].isLike = true;
                this.postList[index].likeCount++;
              } else {
                this.$toast(res.message);
              }
            });
          } else {
            forum.likeCancelArticle({ forumManagementId: id }).then((res) => {
              this.$toast({ message: res.message, duration: 1000 });
              if (res.status == 1) {
                this.postList[index].isLike = false;
                this.postList[index].likeCount--;
              }
            });
          }
        }
      }, 1000);
    },
    collect(id, isCollect, state, index) {
      debounce(() => {
        if (state == 1) {
          if (!isCollect) {
            forum.collectArticle({ forumManagementId: id }).then((res) => {
              if (res.status == 1) {
                if (res.message > 0) {
                  this.$toast("收藏成功！ +" + res.message + " 积分");
                } else {
                  this.$toast("收藏成功！");
                }
                this.postList[index].isCollect = true;
                this.postList[index].collectCount++;
              } else {
                this.$toast(res.message);
              }
            });
          } else {
            forum
              .collectCancelArticle({ forumManagementId: id })
              .then((res) => {
                this.$toast({ message: res.message, duration: 1000 });
                if (res.status == 1) {
                  this.postList[index].isCollect = false;
                  this.postList[index].collectCount--;
                }
              });
          }
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.post_page {
  .post_list {
    .post_item {
      padding: 40px;
      border-color: #e6e6e6;
      border-style: solid;
      border-width: 0 0 1px 0;
      position: relative;
      & > div:first-of-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 16px;
          color: #363d42;
          cursor: pointer;
        }
        a {
          font-size: 16px;
          padding: 0 8px;
          text-align: center;
          line-height: 26px;
          border-radius: 4px;
          border-width: 1px;
          border-style: solid;
          &.havent {
            color: #b3b0b4 !important;
            border-color: #b3b0b4;
          }
          &.already {
            color: #43bf92 !important;
            border-color: #43bf92;
          }
          &.refuse {
            color: #fc1e07 !important;
            border-color: #fc1e07;
          }
        }
      }

      & > p:first-of-type {
        font-size: 16px;
        color: #738294;
        margin: 12px 0 16px 0;
      }
      & > p:nth-of-type(2) {
        font-size: 16px;
        color: #738294;
        line-height: 26px;
        margin: 0 0 20px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        cursor: pointer;
      }
      & > div:nth-of-type(2) {
        display: flex;
        margin: 0 0 32px 0;
        a {
          display: block;
          padding: 0 8px;
          min-width: 34px;
          border: 1px solid #e6e6e6;
          height: 26px;
          line-height: 26px;
          font-size: 16px;
          color: #6b18a8;
          text-align: center;
          font-weight: 700;
          margin: 0 12px 0 0;
        }
      }
      & > div:nth-of-type(3) {
        display: flex;
        & > a {
          display: flex;
          align-items: center;
          margin-right: 28px;
          position: relative;

          span {
            font-size: 16px;
            color: #363d42;
            margin-left: 8px;
          }
          svg {
            transition: all 0.5s;
          }
          svg:first-of-type {
            opacity: 1;
          }
          svg:nth-of-type(2) {
            opacity: 0;
            position: absolute;
            left: 0;
          }

          &:first-of-type {
            svg {
              width: 17px;
              height: 17px;
            }
            &.pointer {
              cursor: pointer;
            }
          }

          &:nth-of-type(2) {
            svg {
              width: 17px;
              height: 21px;
            }
            &.pointer {
              cursor: pointer;
            }
          }

          &:nth-of-type(3) {
            svg {
              width: 18px;
              height: 18px;
            }
          }
          &.active {
            background: none;
            border: 0;
            svg:first-of-type {
              opacity: 0;
            }
            svg:nth-of-type(2) {
              opacity: 1;
            }
          }
        }
      }
      .icon_mark {
        display: none;
        width: 129px;
        height: 101px;
        position: absolute;
        top: 41px;
        right: 55px;
      }
      &:first-of-type {
        border-width: 1px 0 1px 0;
      }
      &.refuse {
        background: #fbfbfb;
        opacity: 0.5;
        .icon_mark {
          display: inline;
        }
      }
    }
  }
  .post_pagination {
    margin: 60px 0 100px 0;

    .el-pagination {
      display: flex;
      justify-content: flex-end;

      /deep/ .el-pager li {
        width: 45px;
        height: 45px;
        font-size: 16px;
        color: #363d42;
        text-align: center;
        line-height: 45px;
      }

      /deep/ .el-pager li.active {
        color: #fff;
        background-color: #6b18a8;
      }

      /deep/ .btn-next {
        width: 95px;
        height: 45px;
        color: #fff;
        background-color: #6b18a8;
      }
    }
  }
  .onBottomLoad_btn {
    display: none;
  }
}

@media (max-width: 750px) {
  .post_page {
    .post_list {
      margin-top: vw(42);
      .post_item {
        padding: vw(40) vw(20);
        border-width: 0 0 vw(1) 0;
        & > div:first-of-type {
          h4 {
            font-size: vw(28);
            height: vw(40);
            white-space: nowrap;
            max-width: calc(100% - 72px);
            overflow: hidden;
            text-overflow: ellipsis;
          }
          a {
            font-size: vw(24);
            line-height: vw(36);
            height: vw(36);
            display: flex;
            align-items: center;
            padding: 0 vw(8);
            border-radius: vw(4);
            border-width: vw(1);
          }
        }

        & > p:first-of-type {
          font-size: vw(20);
          margin: vw(14) 0 vw(24) 0;
        }
        & > p:nth-of-type(2) {
          font-size: vw(24);
          line-height: vw(36);
          margin: 0 0 vw(30) 0;
        }
        & > div:nth-of-type(2) {
          margin: 0 0 vw(35) 0;
          flex-wrap: wrap;
          a {
            padding: 0 vw(22);
            min-width: vw(94);
            border: vw(1) solid #e6e6e6;
            height: vw(50);
            line-height: vw(50);
            font-size: vw(24);
            margin: 0 vw(18) vw(10) 0;
          }
        }
        & > div:nth-of-type(3) {
          a {
            margin-right: vw(56);

            span {
              font-size: vw(24);
              margin-left: vw(11);
            }

            &:first-of-type {
              svg {
                width: vw(25);
                height: vw(25);
              }
            }

            &:nth-of-type(2) {
              svg {
                width: vw(28);
                height: vw(36);
              }
            }

            &:nth-of-type(3) {
              svg {
                width: vw(28);
                height: vw(29);
              }
            }
          }
        }
        .icon_mark {
          width: vw(129);
          height: vw(101);
          top: vw(41);
          right: vw(55);
        }
        &:first-of-type {
          border-width: vw(1) 0 vw(1) 0;
        }
      }
    }
    .noData {
      font-size: vw(32);
    }
    .post_pagination {
      display: none;
    }
    .onBottomLoad_btn {
      display: block;
      width: vw(166);
      height: vw(52);
      color: #738294;
      line-height: vw(52);
      background: #e6e6e6;
      font-size: vw(20);
      text-align: center;
      margin: 0 auto vw(70);
    }
  }
}
</style>
